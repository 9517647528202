import React from 'react'
import style from './wallet.module.scss'
import firstBox from '../../assets/mainPage/firstboxWallet.png';
import secondBox from '../../assets/mainPage/secondboxWallet.png';
import thirdBox from '../../assets/mainPage/thirdboxWallet.png'
import hand from '../../assets/mainPage/walletPhone.png'
import firstBoxMobile from '../../assets//mainPage/firstboxWalletMobile.png'
import secondBoxMobile from '../../assets//mainPage/secondboxWalletMobile.png'
import thirdBoxMobile from '../../assets//mainPage/thirdboxWalletMobile.png'
import phones3 from '../../assets/mainPage/threephones.png'
import phonesoverlay from '../../assets/mainPage/phonesoverlay.png'
export default function Wallet() {
    return (
        <div>
            <div className='mb-10 lg:flex items-end relative text-white border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px'>

                <div className='lg:w-[60%]  p-4 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                    <div className='w-full md:w-[92%] lg:w-[80%]'>
                        <div className={style.beforeBar + ' font-stolziMed font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziBold  font-medium'} > Oveit Wallet</div>
                        <p className='text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>The Oveit Wallet represents the central point of the Oveit
                            ecosystem. It is a digital wallet for experiential shopping that
                            connects with all our products and services: NFT Ticketing,
                            Oveit Pay, and Streams.live web3 commerce. All the
                            transactions in all the Oveit products and services are
                            processed via the Oveit Wallet.</p>

                        <p className='py-4 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>Oveit Wallet is designed to operate online payments by
                            connecting to livestream shopping sessions and making in-stream payments with just one click while continuing to watch
                            the livestream session</p>
                        <p className='text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>Oveit Wallet is also designed to operate in-venue cashless/
                            touchless payments and gamifications using three alternative
                            methods:</p>
                    </div>
                    <div className='flex flex-col lg:flex-row md:w-[92%] text-center gap-6 mt-6 lg:my-16'>
                        <div className='flex-1'>
                            <button className='font-stolziBold w-full mb-2 py-[7px] rounded-[10px] bg-pink text-17px'>PAY WITH WEARABLES</button>
                            <img alt='pinkCircle' className='w-full' src={window.innerWidth >= 767 ? firstBox : firstBoxMobile} />
                        </div>
                        <div className='flex-1'>
                            <button className='w-full font-stolziBold mb-2 py-[7px] rounded-[10px] bg-pink text-17px'>PAY WITH WEARABLES</button>
                            <img alt='pinkCircle' className='w-full' src={window.innerWidth >= 767 ? secondBox : secondBoxMobile} />
                        </div>
                        <div className='flex-1'>
                            <button className='w-full font-stolziBold mb-2 py-[7px] rounded-[10px] bg-pink text-17px'>PAY WITH WEARABLES</button>
                            <img alt='pinkCircle' className='w-full' src={window.innerWidth >= 767 ? thirdBox : thirdBoxMobile} />
                        </div>
                    </div>


                </div>
                <div className='w-full h-full lg:w-[40%] flex justify-end '>
                    <img alt='hand' className='rounded-[20px] ' src={hand} />
                </div>
            </div>
            <div className={style.secondBox}>
                <div className='mb-10  py-4  text-white px-4 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                    <div className={style.beforeBar + ' font-stolziMed  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziBold  font-medium'} >NFT Ticketing</div>
                    <div className='lg:flex gap-8'>
                        <div className='flex-1'>
                            <p className='mb-4  lg:mb-8 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>Oveit NFT tickets are already used by numerous blockchain events worldwide, such as Paris Blockchain Week Summit, NFT | LA, ReFi Summit, NFT.NYC, Australian Crypto Conference, Paris NFT Day, and MultiversX-Days. However, it is only the beginning, and Oveit continues evolving the technology, building on top of its existing infrastructure of ticketing, access control, and cashless payments.</p>
                            <p className='mb-4 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>Oveit partnered with Polygon to mint NFT tickets on the eco-friendly blockchain with low gas fees and high transaction speeds. All ticket sales (and resales) happen on-chain to guarantee security from fraudulent transactions and transparency of the sales process. Thanks to the partnership with Stripe, PayPal, Crypto.com and Utrust, tickets can be purchased in multiple ways, in fiat or cryptocurrency.</p>
                        </div>
                        <div className='flex-1 w-full flex justify-center items-center'>
                            <img className='w-full ' src={phones3} alt="three phones " />
                        </div>
                    </div>

                </div>

            </div>
            <div className={style.secondBox}>
                <div className='my-20 lg:mb-[120px] 3xl:mb-[165px] bg-pink rounded-10px lg:rounded-20px py-4  text-white px-4 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>

                    <div className='lg:flex gap-8 lg:items-center'>
                        <div className='flex-1'>
                            <div className={style.beforeBar + ' ' + style.blueBar + ' font-stolziMed  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziBold  font-medium'} >Oveit Pay</div>

                            <p className='mb-4  lg:mb-8 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>While Oveit Wallet is the user-facing application, Oveit Pay is the vendor-facing application. Oveit has developed a cutting-edge vending solution that is fast and allows complete transparency on mobile transactions. Oveit Pay is a complex point-of-sale (POS) solution that operates NFC (near-field communication), dynamic QR codes and facial biometrics payments, and other features, running on both crypto and fiat denominations.</p>

                        </div>
                        <div className='flex-1 w-[100%] flex justify-center items-center'>
                            <img className='w-[80%] lg:w-[52%] ' src={phonesoverlay} alt="three phones " />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
