import React from 'react';
import styles from './WordList.module.scss'
import second from '../../assets/clientsLogo/2.png'
import third from '../../assets/clientsLogo/3.png';
import four from '../../assets/clientsLogo/4.png';

import five from '../../assets/clientsLogo/5.png';
import six from '../../assets/clientsLogo/6.png'





const WordList = () => {


    return (
        <div>
            <h4 className={styles.wordCarousel}>

                <div>

                    <ul className={styles.flip6} >

                        <li><img src={second} alt='second' /></li>
                        <li><img src={third} alt='third' /></li>
                        <li><img src={four} alt='four' /></li>
                        <li><img src={five} alt='five' /></li>
                        <li><img src={six} alt='six' /></li>
                    </ul>
                </div>
            </h4>

        </div>
    );
};

export default WordList;



