import React, { useState } from 'react';
import styles from './header.module.scss';
import phones from '../../assets/mainPage/phones.png'
import Contact from '../contact/Contact';


export default function Header() {

    const [isContactOpen, setIsContactOpen] = useState(false)


    const openContact = () => {
        setIsContactOpen(true);
    };

    const closeContact = () => {
        setIsContactOpen(false);
    };





    return (
        <div className='text-white p-2 md:flex  md:items-center'>
            <div className='flex flex-col md:flex-1 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                <div className={styles.before + ' text-30px md:text-38px lg:text-50px 3xl:text-[70px] leading-none font-bold mb-6 pl-sm-pad-left-header md:pl-0'}>Oveit,{window.innerWidth > 768 && <br></br>} revolutionizing event experiences worldwide</div>
                <div><p className='font-stolziBook font-light text-14px md:text-18px xl:text-20px 3xl:text-24px font-light mb-8 lg:py-6 xl:py-6 lg:w-4/5'>We are using innovative fintech solutions for experiential shopping, from ticketing and access control to cashless payments, gamified experiences, NFTs and livestream shopping.</p></div>
                <button onClick={openContact} className={styles.buton + '  py-[12px] border-2 lg:w-1/2 vorder-white rounded-10px text-20px font-bold'}>Join The Moment</button>
            </div>
            <div className='relative md:flex-1'>
                <img className='py-12 z-10 relative' src={phones} alt='Oveit phones' />
                <div className='z-1' id={styles.hexagon}></div>
            </div>
            {isContactOpen && <Contact onClose={closeContact} />}


        </div>
    );
}
