import React, { useRef, useEffect } from 'react';
import style from './leadership.module.scss'
import pinkBar from '../../assets/mainPage/pinkBar.svg'
import andreiStefan from '../../assets/mainPage/andreiStefan.png'
import mike from '../../assets/mainPage/mikeMihai.png'
import neil from '../../assets/mainPage/neil.png'
import razvan from '../../assets/mainPage/razvan.png'
import monika from '../../assets/mainPage/monika.png'
import nicolas from '../../assets/mainPage/nicolas.png'
export default function Leadership() {


    const firstBoxRef = useRef();
    const secondBoxRef = useRef();
    const updateBoxHeight = () => {
        const secondBoxHeight = +secondBoxRef.current.clientHeight;
        firstBoxRef.current.style.minHeight = `${secondBoxHeight}px`;
    };
    useEffect(() => {

        if (window.innerWidth > 768) {
            updateBoxHeight();

            window.addEventListener('resize', updateBoxHeight);
        }



        return () => {
            window.removeEventListener('resize', updateBoxHeight);
        };
    }, [firstBoxRef]);

    useEffect(() => {
        updateBoxHeight();
    }, []);



    return (
        <div className='mb-4 md:mb-10 lg:mb-16 3xl:mb-20'>
            <div className='text-white '>
                <div className='mb-4 md:mb-8 lg:mb-12 lg:mb-[100px] 3xl:mb-[190px]'>
                    <div className={style.before + '  font-stolziMed  font-medium bg-blue pl-12 border-2 border-blue rounded-10px lg:rounded-20px py-4 3xl:py-6 text-22px phone:text-30px md:text-38px lg:text-50px 3xl:text-[90px] leading-none px-sm-pad-left-header md:px-md-pad-left-bottom lg:px-lg-pad-left-bottom 3xl:px-xl-pad-left-bottom'}>

                        Leadership</div>
                    <div className='mt-[-17px]  py-8 lg:py-[80px]  lg:mt-[-35px]  md:border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px gap-4 lg:gap-[20%]  md:px-md-pad-left-bottom lg:px-lg-pad-left-bottom 3xl:px-xl-pad-left-bottom  flex flex-col md:flex-row md:justify-between items-center md:items-start'>
                        <div className='fle-1 h-full bg-[#262626] rounded-10px w-full  3xl:max-w-[600px]  '>
                            <div>
                                <img className='w-full' src={andreiStefan} alt='andrei stefan' />

                            </div>
                            <div className='bg-blue w-full p-4'>
                                <p className='font-stolziBold text-20px phone:text-26px lg:text-22px xl:text-38px 3xl:text-50px '>Andrei Stefan</p>
                                <p className='flex items-center gap-2 text-14px md:text-18px xl:text-20px 3xl:text-24px'><img className='lg:w-[50px] 3xl:w-[80px]' src={pinkBar} alt='pink bar' /> Co-founder</p>
                            </div>
                            <div ref={firstBoxRef} className='bg-[#262626] p-4 rounded-10px text-14px md:text-18px xl:text-20px 3xl:text-24px'>
                                <p className='my-2 lg:mb-4 3xl:mb-8'>Coming from the corporate world, Andrei has 20 years of experience in several industries in general management and finance.</p>
                                <p className='mb-2 lg:mb-4 3xl:mb-8'> He has worked in both billion-dollar corporations and startup environments, having an Executive Education from Harvard University and an MBA from Rice University.</p>
                            </div>
                        </div>
                        <div className='mt-2 md:mt-0 flx-1 bg-[#262626] rounded-10px w-full   3xl:max-w-[600px]  '>
                            <div>
                                <img className='w-full' src={mike} alt="mike" />

                            </div>
                            <div className='bg-blue w-full p-4'>
                                <p className='font-stolziBold text-20px phone:text-26px lg:text-22px xl:text-38px 3xl:text-50px hitespace-nowrap'>Mike Mihai Dragan</p>
                                <p className='flex items-center gap-2 text-14px md:text-18px xl:text-20px 3xl:text-24px'><img className='lg:w-[50px] 3xl:w-[80px]' src={pinkBar} alt='pink bar' /> Co-founder</p>
                            </div>
                            <div ref={secondBoxRef} className='bg-[#262626] p-4 rounded-10px text-14px md:text-18px xl:text-20px 3xl:text-24px'>
                                <p className='my-2 lg:mb-4 3xl:mb-8'>Serial entrepreneur Mike has over 15 years of experience in product development and digital marketing. </p>
                                <p className='mb-2 lg:mb-4 3xl:mb-8'> He has received over 30 awards in his career for product innovation and technological achievements.</p>
                                <p className='mb-2 lg:mb-4 3xl:mb-8'>He has several degrees in computer science, bioinformatics, artificial intelligence and digital marketing.</p>
                            </div>
                        </div>


                    </div>

                </div>
                <div className={style.advisors}>
                    <div className={style.before + '  font-stolziMed  font-medium bg-pink pl-12 border-2 border-pink rounded-10px lg:rounded-20px py-4 3xl:py-6 text-22px phone:text-30px md:text-38px lg:text-50px 3xl:text-[90px] leading-none px-sm-pad-left-header md:px-md-pad-left-bottom lg:px-lg-pad-left-bottom 3xl:px-xl-pad-left-bottom'}>

                        Advisors</div>
                    <div className='mt-[-17px]  py-12 lg:pb-[67px] lg:pt-[80px]  3xl:pb-[67px]  lg:mt-[-35px] md:border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px gap-4   md:px-md-pad-left-bottom lg:px-lg-pad-left-bottom 3xl:px-xl-pad-left-bottom  flex flex-col  md:justify-between items-center'>

                        <div className=' mb-4 lg:mb-8  p-4 lg:p-8 3xl:p-16 border-2 border-blue rounded-10px flex  gap-2 md:gap-4 lg:gap-8 3xl:gap-[30px]'>
                            <div className='w-1/4 lg:w-[152px] 3xl:w-[212px]'>
                                <img className='w-full' src={neil} alt="Neil Gomez" />
                            </div>
                            <div className='w-3/4'>
                                <p className='font-stolziBold flex items-center text-10px lg:text-18px xl:text-38px 3xl:text-50px'><img src={pinkBar} alt="pinkBar" className='lg:w-[50px] 3xl:w-[80px] pr-4' />Neil Cunha - Gomez</p>
                                <p className='text-14px md:text-18px xl:text-20px 3xl:text-24px'>Senior executive at the Softbank Vision Fund, leading fintech and crypto investments. Neil is an angel investor in Oveit and has actively advised the company on its growth and market diversification plans.</p>
                            </div>
                        </div>
                        <div className=' mb-4 lg:mb-8 p-4 lg:p-8 3xl:p-16 border-2 border-blue rounded-10px flex flex-row-reverse justify-between gap-2 md:gap-4 lg:gap-8 3xl:gap-[30px]'>
                            <div className='w-1/4 lg:w-[152px] 3xl:w-[212px] justify-self-end'>
                                <img className='w-full' src={razvan} alt="Razvan Munteanu" />
                            </div>
                            <div className='w-3/4'>
                                <p className='font-stolziBold flex items-center text-10px lg:text-18px xl:text-38px 3xl:text-50px'><img src={pinkBar} alt="pinkBar" className='lg:w-[50px] 3xl:w-[80px] pr-4' />Razvan Munteanu</p>
                                <p className='text-14px md:text-18px xl:text-20px 3xl:text-24px'>Successful investor in entertainment, real estate, tech & crypto. Passionate about futurology and emerging tech, such as VR and Metaverse. Early investor in Elrond, Angel investor in Oveit, and visionary supporter of blockchain-based technologies, such as NFTs and AI.</p>
                            </div>
                        </div>
                        <div className=' mb-4 lg:mb-8  p-4 lg:p-8 3xl:p-16 border-2 border-blue rounded-10px flex  gap-2 md:gap-4 lg:gap-8 3xl:gap-[30px]'>
                            <div className='w-1/4 lg:w-[152px] 3xl:w-[212px]'>
                                <img className='w-full' src={monika} alt="Nicolas" />
                            </div>
                            <div className='w-3/4'>
                                <p className='font-stolziBold flex items-center text-10px lg:text-18px xl:text-38px 3xl:text-50px'><img src={pinkBar} alt="pinkBar" className='lg:w-[50px] 3xl:w-[80px] pr-4' />Monika Wilk</p>
                                <p className='text-14px md:text-18px xl:text-20px 3xl:text-24px'>A former executive from Softbank and Goldman Sacks, Monika is an entrepreneur and avid crypto enthusiast, acting as an Angel Investor and Advisor to a number of highly promising startups and growth companies, including Oveit.</p>
                            </div>
                        </div>
                        <div className='  p-4 lg:p-8 3xl:p-16 border-2 border-blue rounded-10px flex flex-row-reverse justify-between gap-2 md:gap-4 lg:gap-8 3xl:gap-[30px]'>
                            <div className='w-1/4 lg:w-[152px] 3xl:w-[212px] justify-self-end'>
                                <img className='w-full' src={nicolas} alt="Razvan Munteanu" />
                            </div>
                            <div className='w-3/4'>
                                <p className='font-stolziBold flex items-center text-10px lg:text-18px xl:text-38px 3xl:text-50px'><img src={pinkBar} alt="pinkBar" className='lg:w-[50px] 3xl:w-[80px] pr-4' />Nicolas Bucspun</p>
                                <p className='text-14px md:text-18px xl:text-20px 3xl:text-24px'>Institutional Partnership Manager at Bitget in the US and former Senior Trade Analyst at prime broker Interactive Brokers, Nicolas is an active professional and networker in the crypto space.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
