import React from 'react'
import styles from './ecosystem.module.scss'
import untwo from '../../assets/mainPage/unwto.png'
export default function Ecosystem() {
    return (
        <div>
            <div className='text-white pb-8 lg:pb-[120px] 3xl:pb-[167px]'>
                <div>
                    <div className={styles.before + ' relative z-4 font-stolziBold  font-medium bg-pink pl-12 border-2 border-pink rounded-10px lg:rounded-20px py-4 3xl:py-6 text-22px phone:text-30px md:text-38px lg:text-50px 3xl:text-70px leading-none pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'}>Oveit Ecosystem</div>
                    <div className='mt-[-17px] pt-8 py-4 lg:pt-[80px]  lg:mt-[-35px] border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px px-4 md:px-md-pad-left-header lg:px-lg-pad-left-header 3xl:px-xl-pad-left-header  lg:flex lg:gap-4'>
                        <div className=' md:flex-1 text-14px md:text-18px xl:text-20px 3xl:text-24px mb-4 font-stolziBook font-light '>
                            <h3 className={styles.beforeBar + ' font-stolziMed  font-medium font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px'}>About Oveit</h3>
                            <p className='lg:w-[90%]'>Oveit is a fintech company providing digital solutions and smart contract applications for experiential shopping. Its products and services form a complex ecosystem of interconnected capabilities ranging from ticketing and access control to cashless payments, gamified experiences, non-fungible tokens (NFTs) and livestream shopping. It serves events, venues and experiences in in-person, online or hybrid format.</p>
                            <p className='lg:w-[90%] py-4'>Oveit started as a SaaS live experiences software in early 2015. Oveit made technology innovations its moving power. In 2019, Oveit was selected as one of the top 5 world’s most disruptive technology innovators by the United Nations World Tourism Organization. Besides that, Oveit has been a nominee and winner of dozens of innovation awards in the USA, Canada, China, Japan, South Korea, Macao SAR, UK, Austria, Germany, Hungary, Ireland, Netherlands, Portugal, Romania, Spain, and Turkey.</p>
                            <p className='lg:w-[90%]'>Oveit provided its services to tens of thousands of events, including such giants as San Antonio Fiesta, with 3.5 million attendees. Amongst its clients, there are such globally renowned brands as Amazon, LVMH, L’Oréal, Microsoft, TED, Visa, Oracle, Samsung, Groupe Renault, Tefal, British American Tobacco, Walgreens-Boots Alliance, Yves-Rocher, Ecco, Avene, International Advertising Association, NEC, Gulfood, Paris Blockchain Summit, NFT LA, NFT NYC, ReFi Summit, Australian Crypto Convention, Paris MultiversX-Days and many others.</p>
                        </div>
                        <div className='md:flex-1 flex justify-center items-center'>

                            <div className=' '>
                                <img className='w-full h-full xl:scale-125' alt='pinkbar' src={untwo} />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}
