import React from 'react';
import ReactDOM from 'react-dom';
import styles from './disclaimer.module.scss'
import { AiOutlineRollback } from "react-icons/ai";
const Backdrop = (props) => {

    return (
        <div className={styles.backdrop} onClick={props.onClose} />);
};

const ModalOverlay = (props) => {

    const handleClose = () => {
        props.onClose();
    };



    return (
        <div>
            <div className={styles.modal + ' scrollbar-hide'}>
                <div>
                    <div className={styles.before + ' font-stolziBold  font-medium bg-blue pl-12 border-2 border-blue rounded-10px lg:rounded-20px py-4 text-30px md:text-38px lg:text-50px 3xl:text-70px leading-none px-sm-pad-left-header md:px-md-pad-left-bottom lg:px-lg-pad-left-bottom 3xl:px-xl-pad-left-bottom'}>{props.title} <button className={styles.closeBtn} onClick={handleClose}><AiOutlineRollback /></button></div>

                    <div className='mt-[-17px]  pt-8 lg:pt-[80px]  lg:mt-[-35px] md:border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px px-4 md:px-md-pad-left-header lg:px-lg-pad-left-header 3xl:px-xl-pad-left-header  md:flex '>
                        <div className='  text-14px md:text-18px xl:text-20px 3xl:text-24px mb-4 font-stolziBook font-light h-full '>


                            {props.children}
                        </div>

                    </div>

                </div>
            </div>

        </div>




    );
};

export default function Disclaimer(props) {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClose={props.onClose} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay onClose={props.onClose} title={props.title}>{props.children}</ModalOverlay>,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
}