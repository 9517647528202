import React from 'react'
import WordList from './Wordlist'
import styles from './clients.module.scss'
import Carousel from './Carousel'
import samsung from '../../assets/clientsLogo/samsung.svg'
import amazon from '../../assets/clientsLogo/amazon.svg'
import tefal from '../../assets/clientsLogo/tefal.svg'
import tedX from '../../assets/clientsLogo/tedX.svg'
import avon from '../../assets/clientsLogo/avon.png'
import avene from '../../assets/clientsLogo/avene.svg'
import microsoft from '../../assets/clientsLogo/microsoft.svg'
import abean from '../../assets/clientsLogo/abeam.png'
import british from '../../assets/clientsLogo/british.svg'
import google from '../../assets/clientsLogo/google.png'
import renault from '../../assets/clientsLogo/renault.svg'
import mastercard from '../../assets/clientsLogo/mastercard.svg'
import lvmh from '../../assets/clientsLogo/lvmh.svg'
import visa from '../../assets/clientsLogo/visa.svg'
import loreal from '../../assets/clientsLogo/loreal.svg'
import paypal from '../../assets/clientsLogo/paypal.svg'
import woman from '../../assets/clientsLogo//woman.svg'
import answer from '../../assets/clientsLogo/Logo_Answear.png'
import yves from '../../assets/clientsLogo/yvesR.svg'
import murmur from '../../assets/clientsLogo/murmur.png'


import altlayer from '../../assets/clientsLogo/altLayer.png'
import utrust from '../../assets/clientsLogo/utrust.png'
import gryfya from '../../assets/clientsLogo//gryfia.png'
import blocksplit from '../../assets/clientsLogo/blocksplit.png'
import brusells from '../../assets/clientsLogo/brussels.png'
import australian from '../../assets/clientsLogo/australian.png'
import d10 from '../../assets/clientsLogo/d10.png'
import nftla from '../../assets/clientsLogo/nftla.png'
import polygon from '../../assets/clientsLogo/polygon.png'
import paris from '../../assets/clientsLogo/parisweek.png'
import nftbuc from '../../assets/clientsLogo/nftbuc.png'
import elrond from '../../assets/clientsLogo/elrond.png'
import daocon from '../../assets/clientsLogo/daocon.png'
import cryptocom from '../../assets/clientsLogo/cryptocom.png'
import refisummit from '../../assets/clientsLogo//refisummit.png'
import triangle from '../../assets/clientsLogo/bluetriangle.png'
import nftnyc from '../../assets/clientsLogo/nftnyc.png'

import deep from '../../assets/clientsLogo/deep.png'
import techsylvania from '../../assets/clientsLogo/techsylvania.png'
import pioneers from '../../assets/clientsLogo/pioneers.png'
import burger from '../../assets/clientsLogo/burger.png'
import flight from '../../assets/clientsLogo/flight.png'
import uuu from '../../assets/clientsLogo/uuu.png'
import bbb from '../../assets/clientsLogo/bbb.png'
import orange from '../../assets/clientsLogo/orange.png'
import howtoweb from '../../assets/clientsLogo/howtoweb.svg'
import effie from '../../assets/clientsLogo/effie.png'
import miez from '../../assets/clientsLogo/miez.png'
import aejm from '../../assets/clientsLogo/aejm.png'
import threeso from '../../assets/clientsLogo/3so.png'
import arsenal from '../../assets/clientsLogo/arsenal.png'
import banca from '../../assets/clientsLogo/georgeenescu.png'
import devplay from '../../assets/clientsLogo/devplay.png'
import awake from '../../assets/clientsLogo/awake.png'
import iaa from '../../assets/clientsLogo/iaa.png'
import falllove from '../../assets/clientsLogo/falllove.png'
import icefest from '../../assets/clientsLogo/iceefest.png'
import pinkBar from '../../assets/mainPage/pinkBar.svg'
import roundedCircle from '../../assets/mainPage/roundedPinkCirle.png'





export default function Clients() {

    const logos = [
        [
            samsung,
            amazon,
            tefal,
            tedX,
            avon,
            avene,
            microsoft,
            abean,
            british,
            google,
            renault,
            lvmh,
            mastercard,
            visa,
            paypal,
            woman,
            answer,
            loreal,
            yves,

            murmur
        ],
        [
            altlayer,
            utrust,
            gryfya,
            blocksplit,
            brusells,
            australian,
            d10,
            nftla,
            polygon,
            paris,
            nftbuc,
            elrond,
            daocon,
            cryptocom,
            refisummit,
            triangle,
            nftnyc
        ],
        [
            deep, techsylvania, pioneers, burger, flight, uuu, bbb, orange, howtoweb, effie, miez, aejm, threeso, arsenal,
            banca, devplay, awake, iaa, falllove, icefest
        ]
    ];





    return (
        <div className='text-white font-stolziMed '>
            <div className='text-white pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                <div className={styles.beforeBar + ' font-stolziMed   my-4 lg:my-8 3xl:mb-[77px]  text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px '} > Our Clients & Partners</div>
            </div>
            <p className='mb-4 text-14px md:text-px xl:text-20px 3xl:text-24px pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>LEADING BRANDS</p>
            <Carousel leading={logos[0]} logoCount={logos[0].length} />
            <p className=' font-stolziMed 3xl:pt-[40px]  my-4 text-14px md:text-px xl:text-20px 3xl:text-24px pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>WEB3 COMPANIES</p>
            <Carousel leading={logos[1]} logoCount={logos[1].length} />
            <p className='my-4 text-14px 3xl:pt-[40px] md:text-px xl:text-20px 3xl:text-24px pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>EVENTS & VENUES</p>
            <Carousel leading={logos[2]} logoCount={logos[2].length} />
            <div className='mt-16 3xl:mt-[165px] text-14px md:text-18px xl:text-20px 3xl:text-24px mb-4 font-stolziBook font-light'>


                <div className=' lg:flex rounded-10px lg:rounded-20px p-4 md:p-8 lg:p-12 3xl:p-16 bg-blue  md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header mb-16  '>
                    <div className='flex-1'>
                        <p className='mb-4 relative'><img alt='bluebar' className={styles.pinkBar + ' inline-block pr-2'} src={pinkBar} /> Oveit is an official partner with regulated fintech pioneers such as Stripe and PayPal. For its crypto capabilities, it partnered with the market leaders Polygon, Crypto.com, Utrust, and MultiversX. Oveit is continuously working on growing its list of partnerships to support its circle of unified fiat and crypto financial services.</p>
                        <p> Now Oveit is bringing all this experience and resources to pioneer the new era of fintech services for the emerging experiential shopping market.</p>
                    </div>
                    <div className='flex-1 min-h-[280px] flex justify-center items-center overflow-hidden'>
                        <img src={roundedCircle} className={styles.cirlce} alt="rounded circle" />

                        <WordList />
                    </div>
                </div>
            </div>
        </div>
    )
}
