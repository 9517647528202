import React from 'react'
import Header from '../components/mainPage/header'
import BlockchainSolution from '../components/blockchainSolution/BlockchainSolution'
import styles from './layout.module.scss'
import Ecosystem from '../components/ecosystem/Ecosystem'
import Introduction from '../components/introduction/Introduction'
import Clients from '../components/clients/Clients'
import Wallet from '../components/wallet/Wallet'
import Token from '../components/token/Token'
import RoadMap from '../components/roadMap/RoadMap'
import Leadership from '../components/leadership/Leadership'
import Footer from '../components/footer/Footer'
export default function Home() {

  return (
    <div >


      <div className=' mx-auto '>
        <div className=''>
          <div id="header">
            <Header />
          </div>

          <Introduction />
          <BlockchainSolution />
          <div id="oveit-ecosystem">
            <Ecosystem />
          </div>

          <Clients />
          <Wallet />

          <div id="oveit-token">
            <Token />
          </div>
          <div id="roadmap">
            <RoadMap />
          </div>

          <Leadership />
          <Footer />
        </div>

      </div>
    </div>
  )
}
