import React from 'react'
import style from './token.module.scss'
import pinkBar from '../../assets/mainPage/pinkBar.svg'
export default function Token() {
    return (
        <div className='text-white'>
            <div>
                <div className={style.before + ' relative z-4 font-stolziBold  font-medium bg-blue pl-12  rounded-10px lg:rounded-20px py-4 3xl:py-6 text-22px phone:text-30px md:text-38px lg:text-50px 3xl:text-70px leading-none pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'}>Oveit Token</div>
                <div className='mt-[-17px]  pt-8 lg:pt-[80px]  lg:mt-[-35px] border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px px-4 lg:pr-8 lg:pb-16 md:pl-md-pad-left-header  lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header  md:flex'>
                    <div className=' md:flex-1 text-14px md:text-18px xl:text-20px 3xl:text-24px mb-4 font-stolziBook font-light '>
                        <div className={style.beforeBar + ' font-stolziMed font-medium mb-4 lg:mb-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziBold  font-medium'} >Utility and Staking</div>
                        <p className='md:w-[90%] lg:mb-8 3xl:mb-16'>The Oveit token lies at the intersection of the different components within the Oveit ecosystem, and acts as the backbone of the ecosystem’s internal transactions and rewards program. The Oveit token delivers the core functionalities of the ecosystem for both B2B consumers and end-consumers of live experiences.</p>
                        <p className='text-bold '> Oveit token is the utility token associated to:</p>
                    </div>
                    <div className='flex-1 flex flex-col gap-4 lg:gap-8 3xl:gap-8 items-center mb-4 lg:mb-0'>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150 ' alt='pinkbar' src={pinkBar} />
                            <p>Issuing an NFT Ticket Set</p>
                        </div>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150' alt='pinkbar' src={pinkBar} />
                            <p>Managing an NFT Ticket Set</p>
                        </div>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150' alt='pinkbar' src={pinkBar} />
                            <p>Event Management</p>
                        </div>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150' alt='pinkbar' src={pinkBar} />
                            <p>Oveit payments</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
