import "./App.scss";

import ReactDOM from "react-dom/client";
import {

  Route,

  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";

import Layout from "./pages/Layout";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />


      <Route path="/*" element={<Error />} />
    </Route>
  )
);





function App() {
  return (
    <div className="bg-black">
      <RouterProvider router={router} />
    </div>

  );
}

export default App;
