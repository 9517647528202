import React from "react";
import ReactDOM from "react-dom";
import styles from "./contact.module.scss";
import pinkBar from "../../assets/mainPage/pinkBar.svg";
import { AiOutlineRollback } from "react-icons/ai";
const Backdrop2 = (props) => {
  return <div className={styles.backdrop} onClick={props.onClose} />;
};

const ModalOverlay2 = (props) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      <div className={styles.modal + " "}>
        <div className=" mb-8  rounded-10px bg-pink px-4 py-4 font-stolziMed font-medium text-white md:pl-md-pad-left-header lg:rounded-20px lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header">
          <div
            className={
              styles.beforeBar +
              "  my-4 pl-sm-pad-left-header text-26px md:pl-0 lg:my-8 lg:text-30px xl:text-38px 3xl:text-50px"
            }
          >
            Contact us{" "}
            <button className={styles.closeBtn} onClick={handleClose}>
              <AiOutlineRollback />
            </button>
          </div>
          <div className="mb-12 gap-8 md:flex">
            <div className="mb-6 flex-1 md:mb-0">
              <p className="text-16px mb-2 lg:text-20px">Write an email at:</p>
              <a
                href="mailto:contact@oveit.org"
                className="text-16px flex max-w-[455px] items-center rounded-[12px] bg-blue py-2 pl-8 font-bold lg:text-20px"
              >
                {" "}
                <img alt="pinkbar" className="pr-4" src={pinkBar} />{" "}
                contact@oveit.org
              </a>
            </div>
            <div className="flex-1">
              <p className=" text-16px mb-2 lg:text-20px">Twitter:</p>
              <a
                href=" https://twitter.com/oveitorg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-16px flex max-w-[455px] items-center rounded-[12px] bg-blue py-2 pl-8 font-bold lg:text-20px"
              >
                {" "}
                <img alt="pinkbar" className="pr-4" src={pinkBar} /> Join us
              </a>
            </div>
          </div>
          <p className="text-16px  my-4 lg:my-8 lg:text-20px  ">
            A member of our team will contact you shortly!
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Contact(props) {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop2 onClose={props.onClose} />,
        document.getElementById("backdrop-root2")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay2 onClose={props.onClose} title={props.title}>
          {props.children}
        </ModalOverlay2>,
        document.getElementById("overlay-root2")
      )}
    </React.Fragment>
  );
}
