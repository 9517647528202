import React from 'react'
import styles from './blockchainSolution.module.scss'
import pinkBar from '../../assets/mainPage/pinkBar.svg'
import blueBar from '../../assets/mainPage/blueBarBoxes.svg'
export default function BlockchainSolution() {
    return (
        <div>
            <div className={styles.firstBox + ' '}>
                <div className='mb-8 rounded-10px lg:rounded-20px py-4 bg-pink text-white px-4 md:px-md-pad-left-header lg:px-lg-pad-left-header 3xl:px-xl-pad-left-header'>
                    <div className={styles.beforeBar + ' font-stolziMed  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px'} > Blockchain Solution</div>
                    <p className='text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>Leaders of the blockchain market have been naming NFTs (Non-Fungible Tokens) as the answer to the ticketing market crisis. Blockchain technology will not only allow to eliminate security risks and provide means for creators to connect with their community but completely transform the secondary ticketing market.</p>
                    <p className='my-4  lg:my-8 text-14px md:text-px xl:text-20px 3xl:text-24px  font-bold'>Aside from answering the pressing issues of the current market, NFT tickets offer a few extra perks:</p>
                </div>
                <div className={styles.parent + ' text-white  text-14px md:text-px xl:text-20px 3xl:text-24px flex flex-col md:flex-row gap-4 md:flex-wrap mb-16 lg:mb-[120px] 3xl:mb-[178px]'}>
                    <div className={styles.div1 + '  flex-1 p-4 md:py-4  xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 px-4 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> NFT tickets are evergreen. No need for paper printing, fear of losing the physical ticket, or fear of forged, non-traceable paper-printed tickets.</span>
                    </div>
                    <div className={styles.div2 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> NFTs can recover a long-forgotten role of paper tickets - keepsakes that people want to hold on to as memorabilia to reminisce. The NFT ticket with encapsulated digital art can itself become a collectible to be resold even after the event it initially provided access to. It might give birth to a new market of collectible memorable moments turned into assets.</span>
                    </div>
                    <div className={styles.div3 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> The NFT tickets can still be accessed anytime via digital wallets. Experience creators can set up side events and create loyalty programs with points earned and spent during different events or outside of them. They can partner with third parties to extend their membership benefits, launch exclusive clubs for holders of specific NFT tickets or even extend access to other venues.</span>
                    </div>
                    <div className={styles.div4 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> With NFT tickets, experience creators have control over what happens after the ticket is sold: they can choose whether they allow it to be transferred or resold, set the upper and lower resale limit, and claim royalties on resales.</span>
                    </div>
                    <div className={styles.div5 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> Given the NFTs' persistent nature and the ability to build on top of their existing properties, their use can be extended far beyond the initial access to an event or venue. Unlike now, when ticket issuers get fake details of scalpers' bots, with NFT tickets, they will be able to know who exactly their true participants are and how to reach out to them. For example, they can send them news and updates during the event, announce promo offers or launch gamification campaigns.</span>
                    </div>
                    <div className={styles.div6 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-blue rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2' alt='bluebar' src={pinkBar} /> The underlying NFTs' technology guarantees security through dynamic QR codes and allows for authenticity checks. NFTs are not just hyped artwork but digital ownership contracts. They are secure, easily transmissible via blockchain, and governed by strict rules under smart contracts. It is nearly impossible to hack them and fake the ticket.</span>
                    </div>
                </div>

            </div>

            <div className={styles.secondBox + ' mb-16 3xl:mb-[178x]'}>
                <div className='mb-8 rounded-10px lg:rounded-20px py-4 bg-blue text-white px-4 md:px-md-pad-left-header lg:px-lg-pad-left-header 3xl:px-xl-pad-left-header'>
                    <div className={styles.beforeBar + ' font-stolziMed  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziBold  font-medium'} > Market Opportunity</div>
                    <p className='mb-4  lg:mb-8 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light'>The 2020 COVID-19 pandemic was a Black Swan event that has reset the entertainment, travel and retail market. It was a likely but improbable event that has caused several historical changes in the global market:</p>

                </div>
                <div className={styles.parent + ' text-white  text-14px md:text-px xl:text-20px 3xl:text-24px flex flex-col md:flex-row gap-4 md:flex-wrap'}>
                    <div className={styles.div1 + '  flex-1 p-4 md:py-4  xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 px-4 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2 ' alt='bluebar' src={blueBar} /> For events, travel and tourism, the world came to a screeching halt in March 2020. The losses were estimated at 2.5 to almost 4.9 trillion US dollars14. Nowadays, key players in these industries are looking for ways to recover, as financial resources are becoming scarce at the brink of recession and historical inflation.</span>
                    </div>

                    <div className={styles.div4 + '  flex-1 p-4 md:py-4 xl:py-8 3xl:py-12 md:pr-2 lg:pr-4 xl:pr-8 3xl:pr-12 md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px flex'}>
                        <span ><img className='inline-block pr-2 ' alt='bluebar' src={blueBar} />At the same time, event and travel demand bounced back in 2022, with avid travelers looking for live experiences after two years of lockdowns. While this demand exists, the events and tourism industry must reshuffle to incentivize experience-buyers in 2023 and beyond.</span>
                    </div>

                </div>
            </div>



        </div >
    )
}
