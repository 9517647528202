import React, { useState, useEffect, useRef } from 'react';
import styles from './introduction.module.scss'
import pinkBar from '../../assets/mainPage/pinkBar.svg'
import hexagonBlue from '../../assets/mainPage/hexagonBlue.png'
export default function Introduction() {

    const [counter1, setCounter1] = useState(0);
    const [counter2, setCounter2] = useState(0);
    const counterRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust this threshold value as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    startCounter1();
                    startCounter2();
                }
            });
        }, options);

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    const startCounter1 = () => {
        let currentCount = 0;
        const counterInterval = setInterval(() => {
            currentCount++;
            setCounter1(currentCount);
            if (currentCount >= 65) {
                clearInterval(counterInterval);
            }
        }, 30); // Adjust the interval as needed

        // Cleanup function
        return () => {

        };
    };

    const startCounter2 = () => {
        let currentCount = 0;
        const counterInterval = setInterval(() => {
            currentCount++;
            setCounter2(currentCount);
            if (currentCount >= 35) {
                clearInterval(counterInterval);
            }
        }, 30); // Adjust the interval as needed

        // Cleanup function
        return () => {

        };
    };


    return (
        <div ref={counterRef} className='text-white relative z-99'>
            <div className='relative z-5'>
                <div className={styles.before + '  z-4 font-stolziBold  font-medium bg-pink pl-12 border-2 border-pink rounded-10px lg:rounded-20px py-4 3xl:py-6 text-22px phone:text-30px md:text-38px lg:text-50px 3xl:text-70px leading-none pl-sm-pad-left-header md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'}>Introduction</div>
                <div className=' relative z-[20]  mt-[-17px]  pt-8 lg:pt-[80px]  lg:mt-[-35px] border-2 lg:border-[4px] border-t-0 border-t-transparent  border-pink  rounded-10px lg:rounded-20px px-4 md:px-md-pad-left-header lg:px-lg-pad-left-header 3xl:px-xl-pad-left-header  md:flex'>

                    <div className=' md:flex-1 text-14px md:text-18px xl:text-20px 3xl:text-24px mb-4 font-stolziBook font-light '>
                        <p className='md:w-[90%]'>The younger generations, Gen Z and Millennials, are
                            rapidly changing shopping consumer behavior.
                            Experiences such as events, travel and tourism are
                            quickly outpacing goods as the main object of desire.
                            More and more people favor experience over goods,
                            which shows in the attendance of festivals worldwide
                            and the increased networking effects in social media</p>
                    </div>
                    <div className='md:flex-1'>
                        <div className='flex gap-2 lg:gap-4 mb-10 lg:mb-12'>
                            <p className={styles.leftProcent + ' flex items-center justify-center lg:justify-start lg:pl-8  w-[65%] border-2 lg:border-[4px] border-white rounded-10px lg:rounded-20px py-1 lg:py-2 lg:pb-4'}>
                                <div>
                                    <p className='flex'><div className={styles.procent65}>{counter1}</div>%</p>
                                    <p className='mt-[-12px] text-20px'>Experimental</p>
                                </div>
                            </p>
                            <p className={styles.rightProcent + ' flex items-center justify-center lg:justify-start lg:pl-8 w-[35%] border-2 lg:border-[4px] border-white rounded-10px lg:rounded-20px py-1 lg:py-2 lg:pb-4'}>
                                <div>
                                    <p className='flex'><div className={styles.procent35}>{counter2}</div>%</p>
                                    <p className='mt-[-12px]'>Material</p>
                                </div>
                            </p>
                        </div>
                        <div>
                            <p className='text-16px phone:text-20px lg:text-26px 3xl:text-35px font-bold mb-4 lg:mb-12'>Purchase type</p>
                            <div className='lg:flex text-14px lg:text-20px lg:font-bold w-full  mb-8 lg:mb-12'>
                                <ul className={styles.firstUL + ' mb-4 flex-1'}>
                                    <li>Experimental "Other"</li>
                                    <li>Food & Drink</li>
                                    <li>Entertainment</li>
                                    <li>Travel</li>
                                    <li>Outdoor Activities & Recreation</li>
                                </ul>
                                <ul className={styles.secondUL + ' mb-4 flex-21'}>
                                    <li>Electronic Goods</li>
                                    <li>Material "Other"</li>
                                    <li>Transportation</li>
                                    <li>home Goods & furniture</li>
                                    <li>Clothing / Accesories / Jewellery</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='z-1 relative' id={styles.hexagon}><img alt='pinkCircle' src={hexagonBlue} /> </div>
            </div>
            <div className='relative z-2 md:px-0 py-4xl lg:py-[120px] 3xl:py-[167px] md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                <div className={styles.beforeBar + ' font-stolziBold  font-medium  mb-6 lg:mb-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px'}>Ticketing Market</div>
                <div className='lg:flex gap-12'>
                    <div className='flex-1 px-2 md:px-0 mb-8 lg:mb-0 text-14px md:text-px xl:text-20px 3xl:text-24px  font-stolziBook font-light '>
                        <p className='lg:w-[90%] mb-4 lg:mb-8'>Since its inception, event ticketing has progressed tremendously. From a simple method to track and manage audiences at small-scale events such as a theater play or local sports tournament, it has evolved into a multi-billion-dollar industry ranging from tickets to live entertainment and sports events to tourism, delivered in-person or virtually. </p>
                        <p className='lg:w-[90%]'>However, ticketing does not keep pace with the growth of the experience market, and today the outdated ticketing system became its most distressed element. The following are the most pressing issues:</p>
                    </div>
                    <div className='flex-1 flex flex-col gap-4 lg:gap-8 3xl:gap-16 items-center'>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150 ' alt='pinkCircle' src={pinkBar} />
                            <p>Scalping</p>
                        </div>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150' alt='pinkCircle' src={pinkBar} />
                            <p>Security risks</p>
                        </div>
                        <div className='flex-1 text-16px phone:text-20px font-bold lg:text-26px 3xl:text-38px flex items-center p-2 w-full bg-blue rounded-10px lg:rounded-20px'>
                            <img className='px-4 md:px-8 3xl:px-16 lg:scale-150' alt='pinkCircle' src={pinkBar} />
                            <p>Lack of support</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
