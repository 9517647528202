import React from 'react'
import style from './roadMap.module.scss'
import pinkCircle from '../../assets/mainPage/pinkcircle.svg'
import pinkTriangle from '../../assets/mainPage/pinkTriangle.svg'
import pinkSquare from '../../assets/mainPage/pinkSquare.svg'
import pinkStar from '../../assets/mainPage/pinkStar.svg'

import blueCircle from '../../assets/mainPage/blueCircle.svg'
import blueTriangle from '../../assets/mainPage/blueTriangle.svg'
import blueSquare from '../../assets/mainPage/blueSquare.svg'
import blueStar from '../../assets/mainPage/blueStar.svg'


export default function RoadMap() {
    return (
        <div className='my-12 3xl:my-20 lg:mb-[140px] 3xl:mb-[220px]'>
            <div className='mb-10  py-4  text-white  md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'>
                <div className={style.beforeBar + ' ml-5 md:ml-0  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziMed  font-medium'} >End Users</div>
                <div className='relative'>
                    <div className={style.rectangle}></div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-blue rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={pinkCircle} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed mb-2 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Basic Tier</p>
                            <p className=' font-stolziBook  text-12px md:text-px xl:text-20px 3xl:text-24px'>The starting point of every account. Allows access to events, tickets, payments, transfers and various postings with the ecosystem. Free to join.</p>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-blue rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '    '}>
                            <img src={pinkTriangle} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Silver Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>On top of the Basic Tier, the Silver Tier offers access to exclusive deals, private events, discounts from merchants and exclusive perks, by choosing one of the 3 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$98 /month or US$980 /year (paid in advance)</li>
                                <li>Stake US$2,000 worth of Oveit token for one year</li>

                                <li>Generate transactions worth of US$30,000 in one year (granted based on previous year’s transactions)</li>

                            </ul>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-blue rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={pinkSquare} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Gold Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>On top of the Silver Tier, the Gold Tier offers more exclusive deals and events, and allows cashbacks on your purchases within the Oveit ecosystem, by choosing one of the 3 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$196 /month or US$1960 /year (paid in advance)</li>
                                <li>Stake US$4,000 worth of Oveit token for one year</li>

                                <li>Generate transactions worth of US$60,000 in one year (granted based on previous year’s transactions)</li>

                            </ul>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-8 3xl:mb-14 lg:mb-[20px] 3xl:mb-[40px] bg-blue rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={pinkStar} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Platinum Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>The highest Oveit consumer status tier, it offers access to all the exclusive deals, the most premium events, the highest cashback rates, and access to the exclusive Oveit VIP invite-only group, where the best of the best events are taking place, around the world. Accessible by choosing one of the 3 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$490 /month or US$4,900 /year (paid in advance)</li>
                                <li>Stake US$10,000 worth of Oveit token for one year</li>

                                <li>Generate transactions worth of US$150,000 in one year (granted based on previous year’s transactions)</li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div className={style.secondComp + ' mb-10  py-4  text-white  md:pl-md-pad-left-header lg:pl-lg-pad-left-header 3xl:pl-xl-pad-left-header'}>
                <div className={style.beforeBar + ' ml-5 md:ml-0  font-medium my-4 lg:my-8 pl-sm-pad-left-header md:pl-0 text-20px phone:text-26px lg:text-30px xl:text-38px 3xl:text-50px font-stolziMed  font-medium'} >Merchants</div>
                <div className='relative'>
                    <div className={style.rectangle}></div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-pink rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={blueCircle} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed mb-2 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Basic Tier</p>
                            <p className=' font-stolziBook  text-12px md:text-px xl:text-20px 3xl:text-24px'>The starting point of every account. Allows access to events, tickets, payments, transfers and various postings with the ecosystem. Free to join.</p>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-pink rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '    '}>
                            <img src={blueTriangle} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Silver Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>On top of the Basic Tier, the Silver Tier offers access to exclusive features, such as extra benefits and tools for event management and commercial operations, becoming a verified Oveit partner with access to exclusive promo deals, and commercial benefits. <br /> <br />Accessible by choosing one of the 4 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$833 /month or $US8,330 /year (paid in advance) </li>
                                <li>Stake US$17,000 worth of Oveit token for one year</li>

                                <li>Generate transactions worth of US$255,000 in one year (granted based on previous year’s transactions) </li>
                                <li>Accept Oveit token as payment method, and offer 10% discount on all purchases across the Oveit ecosystem</li>

                            </ul>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-[20px] 3xl:mb-[40px] bg-pink rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={blueSquare} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 lg:mb-8  mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Gold Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>On top of the Silver Tier, the Gold Tier offers active promotion of your business in the Oveit ecosystem, priority business placing on exclusive deals and transactional fees rebates, by choosing one of the 3 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$1,764 /month or US$17,640 /year (paid in advance) </li>
                                <li>Stake US$36,000 worth of Oveit token for one year </li>

                                <li>Generate transactions worth of US$540,000 in one year (granted based on previous year’s transactions), AND accept Oveit token as payment method, and offer 5% discount on all purchases across the Oveit ecosystem</li>

                            </ul>
                        </div>
                    </div>
                    <div className='mb-[14px] lg:mb-8 3xl:mb-14 lg:mb-[20px] 3xl:mb-[40px] bg-pink rounded-10px gap-4 lg:rounded-20px flex pt-4 pl-4'>
                        <div className={style.leftBox + '   flex items-center justify-center '}>
                            <img src={blueStar} alt='pinkCircle' />
                        </div>
                        <div className='mb-4 mr-4 lg:mr-12 3xl:mr-16'>
                            <p className=' font-stolziMed 3xl:mt-4 text-20px lg:text-30px xl:text-38px 3xl:text-50px'>Platinum Tier</p>
                            <p className='my-2 text-12px md:text-14px xl:text-20px 3xl:text-24px'>The highest Oveit business status tier, it offers top placement in the respective business category, access to all the Oveit business management tools, and the lowest operational fees and highest business rebates. On top of this, get the chance to promote exclusive deals to Platinum Tier End-Users. Accessible by choosing one of the 3 options:</p>
                            <ul className={style.firstUL + ' font-stolziMed mb-4  text-12px md:text-14px xl:text-20px '}>
                                <li>Pay US$4165 /month or US$41,650 /year (paid in advance) </li>
                                <li>Stake US$85,000 worth of Oveit token for one year </li>

                                <li>Generate transactions worth of US$1,275,000 in one year (granted based on previous year’s transactions), AND accept Oveit token as payment method, and offer 5% discount on all purchases across the Oveit ecosystem

                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
