import React, { useEffect, useState } from 'react'
import style from './carousel.module.scss'

import Slider from "react-slick";

export default function Carousel(props) {
    const [isSliderReady, setSliderReady] = useState(false);
    var settings = {

        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        lazyLoad: true,

        accessibility: false,
        dots: false,
        centerPadding: '30px',
        adaptiveHeight: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1224,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            }

        ]
    };

    useEffect(() => {
        setSliderReady(true);
    }, [props.leading]);


    return (
        <div className={style.firstSlide + ' text-white border-2 lg:border-[4px] border-pink rounded-10px lg:rounded-20px py-4'}>
            {isSliderReady ? (
                <Slider {...settings} className='flex  items-center ' style={{
                    width: '100%', maxHeight: '121px'
                }} >
                    {props.leading.slice(0, props.logoCount).map((logo, index) => (
                        <div key={index}>
                            <img src={logo} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            )
                : null
            }
        </div>
    )
}
